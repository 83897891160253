<template>
  <div class="app-container">
    <div class="head-container">
      <!-- <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" :clearable="false" unlink-panels range-separator="至" start-placeholder="起始日期" end-placeholder="截止日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 280px;" @change="toQuery" /> -->
      <div class="c h">
        <el-date-picker v-model="query.begDate" value-format="timestamp" placeholder="起始日期" style="width:185px;" class="filter-item" />
        <el-date-picker v-model="query.endDate" value-format="timestamp" placeholder="截止日期" style="width:185px;" class="filter-item" />
      </div>
      <el-input v-model="query.keywords" clearable placeholder="经销商编码、名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <!-- <quick-select
        class="filter-item"
        v-model="query.erpId"
        filterable
        value-field="erpId"
        url="api/distributor"
        :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }"
        placeholder="请选择经销商"
        @change="toQuery"
        style="width: 230px;"
        clearable
      >
        <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
      </quick-select> -->
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data">
      <el-table-column prop="erpId" label="ERP编码" min-width="100" show-overflow-tooltip />
      <el-table-column prop="name" label="经销商名称" min-width="150" show-overflow-tooltip />
      <el-table-column prop="balance" label="期初" width="180">
        <template slot-scope="scope">{{$price(scope.row.balance+scope.row.exMoney+scope.row.usedMoney-scope.row.inMoney)}}</template>
      </el-table-column>

      <el-table-column label="获得返利" width="220" align="right">
        <template slot-scope="scope">
          {{$price(scope.row.inMoney)}}&emsp;
          <a class="primary" @click="handleDetail(scope.row, 0)">明细</a>
        </template>
      </el-table-column>
      <el-table-column label="使用返利" width="220" align="right">
        <template slot-scope="scope">
          {{$price(scope.row.usedMoney)}}&emsp;
          <a class="primary" @click="handleDetail(scope.row,1)">明细</a>
        </template>
      </el-table-column>
      <el-table-column label="过期返利" width="220" align="right">
        <template slot-scope="scope">
          {{$price(scope.row.exMoney)}}&emsp;
          <a class="primary" @click="handleDetail(scope.row, 2)">明细</a>
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="期末" width="180" align="right" :formatter="$price" />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <detail ref="detail" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import Detail from "./summary-detail";

export default {
  components: { Detail },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      query: {
        // dateRange: null,
        begDate: null,
        endDate: null,
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    let now = new Date(this.$now.get());
    this.query.begDate = new Date(
      now.getFullYear(),
      0,
      1
    ).getTime();
    (this.query.endDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ).getTime()),
      this.init();
    // (this.query.dateRange = [
    //   new Date(
    //     now.getFullYear(),
    //     now.getMonth() - 1,
    //     now.getDate()
    //   ).getTime(),
    //   new Date(
    //     now.getFullYear(),
    //     now.getMonth(),
    //     now.getDate(),
    //     23,
    //     59,
    //     59
    //   ).getTime(),
    // ]);
  },
  methods: {
    beforeInit() {
      this.url = "api/capitalPool/rebateReport";
      let query = JSON.parse(JSON.stringify(this.query));
      // if (query.dateRange && query.dateRange.length === 2) {
      //   query.begDate = query.dateRange[0];
      //   query.endDate = query.dateRange[1];
      // }
      // delete query.dateRange;
      this.params = query;
      return true;
    },
    afterLoad(res) {
      res.forEach((o) => {
        o.inMoney = Math.abs(o.inMoney);
        o.usedMoney = Math.abs(o.usedMoney);
        o.exMoney = Math.abs(o.exMoney);
      });
    },
    handleDetail(row, type) {
      this.$refs.detail && this.$refs.detail.research(row, type, this.params);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/capitalPool/rebateReport/download", this.params)
        .then((result) => {
          downloadFile(result, "返利汇总", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>